<template>
  <div>
    <div>
      <div class="mainTitle">智能化排课</div>
      <div class="content">
        智能排课系统通过设定基础数据、教师安排、排课规则和约束条件，然后进行排课。智能排课系统最大限度减少人工排课的工作量，同时实现对教学场地和教师资源的合理安排，最大
        限度利用教学资源。
      </div>
      <img src="@/assets/productcore/jiaoxue1.png" alt="" />
    </div>
        <div>
      <div class="mainTitle">考勤管理</div>
      <div class="content">
        面向教育、政府、企业等各行业用户群体的智能化考勤管理、上课、上下班打卡签到。支持一天多次打卡，适应各种类型打卡要求。固定排班、排课考勤打卡方式，满足不同需求。支
持地理位置范围或者Wifi打卡、弹性考勤,让管理更具人性化。
      </div>
      <img src="@/assets/productcore/jiaoxue2.png" alt="" />
    </div>
            <div>
      <div class="mainTitle">教材管理</div>
      <div class="content">
        配套教材管理是一种面向教育行业用户群体的管理系统。电子教材作为纸本教材的延伸，具备低成本、易管理、便保存等特点。丰富师生课余时间，随时随地翻阅，提高师生素养。
      </div>
      <img src="@/assets/productcore/jiaoxue3.png" alt="" />
    </div>
                <div>
      <div class="mainTitle">综合档案管理</div>
      <div class="content">
    面向教育行业用户群体的智能化管理。内容包括学员基本信息︰姓名、年龄、性别、学历、社会关系、政治面貌等﹔动态信息∶学习履历、升学及其变迁、学习轨迹、荣誉和奖励等。
      </div>
      <img src="@/assets/productcore/jiaoxue4.png" alt="" />
    </div>
  </div>
</template>

<script>
export default {
  name: "teaching-manage",
};
</script>

<style scoped>
.mainTitle {
  font-size: 30px;
  color: #333;
  margin: 56px 0 40px;
  position: relative;
}
.mainTitle::after {
  content: "";
  width: 100px;
  height: 4px;
  background-color: #d7d7d7;
  position: absolute;
  left: 50%;
  bottom: -2px;
  transform: translateX(-50%);
}
.content {
  color: #333;
  font-size: 21px;
  line-height: 30px;
  margin-bottom: 32px;
  letter-spacing: 0.2px;
}
img {
  width: 86%;
}
</style>